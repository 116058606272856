<template>
    <v-container fluid style="margin-bottom:150px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card class="p-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    @change="(event) => updateDepartment(event)"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="department"
                                    :items="departments"
                                    item-value="dept_id"
                                    item-text="dept_name"
                                    label="Department"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    clearable
                                    solo
                                    dense
                                    v-model="approve"
                                    :items="approves"
                                    item-value="value"
                                    item-text="descr"
                                    label="Approved"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    clearable
                                    solo
                                    dense
                                    v-model="status"
                                    :items="statuses"
                                    item-value="value"
                                    item-text="status"
                                    label="Status"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="3" md="3" >
                                <v-btn
                                class="mr-2"
                                color="error"
                                elevation="2"
                                large
                                rounded
                                @click="search()"
                                >Search</v-btn>
                            </v-col> -->
                            <v-col class="col-12" sm="6" md="6">
                                <v-btn class="border-12" style="padding: 20px;"
                                color="info" elevation="2" small @click="getPullData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card v-show="display == 'none' ? false: true" class="p-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="purchases"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="search" 
                                    :item-class="tr_datatable"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Result </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.dt_pr`]="{ item }">
                                        {{ (new Date(new Date(item.dt_pr) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                    </template>
                                    <template v-slot:[`item.approve1`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <div v-if="$store.getters.UsernameUser === user_approve1">
                                                    <v-chip
                                                    class="ma-2"
                                                    color="primary"
                                                    v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && item.dt_aprv2 == '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    @click="setApprove1(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-check-circle
                                                        </v-icon>
                                                        Confirmation
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 != '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    @click="setUnApprove(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-replay
                                                        </v-icon>
                                                         Confirmation
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_rjc != '1900-01-01 00:00:00.000'"
                                                    >
                                                         Rejected
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    >
                                                        Not Approved
                                                    </v-chip>
                                                    
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    text-color="white"
                                                    v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_rjc != '1900-01-01 00:00:00.000'"
                                                    >
                                                         Rejected
                                                    </v-chip>
                                                </div>
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.approve2`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    v-on="on"
                                                    >
                                                        Not Approved
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    text-color="white"
                                                    v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    v-on="on"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_rjc != '1900-01-01 00:00:00.000'"
                                                    v-on="on"
                                                    >
                                                         Rejected
                                                    </v-chip>
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-5">
                                            Detail PR Item
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-2"
                                            fab
                                            x-small
                                            color="#e83e8c;"
                                            @click="close"
                                        >
                                            <v-icon dark>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            dense
                                            :headers="header_po_items"
                                            :items="po_items"
                                            :items-per-page="30"
                                            :footer-props="{
                                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                            }"
                                            :loading="$store.state.overlay"
                                            height="425"
                                            fixed-header
                                            :divider="true"
                                            :light="true"
                                            :search="search_item" 
                                            :item-class="tr_datatable"
                                        >    
                                            <template v-slot:top>
                                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                                    <div class="d-flex w-100">
                                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Item </h6>
                                                        <v-spacer></v-spacer>
                                                        <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="search_item" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.qty) }}
                                            </template>
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogAgree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogAgree = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading4"
                            :disabled="loading4"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove1()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                                Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogAgree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogAgree2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree"
                persistent
                max-width="500"
                >
                    <v-card class="p-2">
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 2 this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'PPSK',
                disabled: false,
                href: '/admin/ppsk',
                },
                {
                text: 'Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Purchase Request',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            search_item: '',
            loading: false,
            offices: [],
            office: '',
            departments: [],
            department: '',
            approves: [
                {
                    descr: 'All',
                    value: 'all'
                },
                {
                    descr: 'Not Approved',
                    value: 'approve1'
                },
                // {
                //     descr: 'Not Approved 2',
                //     value: 'approve2'
                // },
                {
                    descr: 'Rejected',
                    value: 'reject'
                }
            ],
            approve: 'all',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'block',
            purchases: [],
            headers: [
                { text: '',  value: 'actions', sortable: false, width: 20 },
                {
                    text: 'PR ID',
                    align: 'start',
                    sortable: false,
                    value: 'pr_id',
                    width: 80
                },
                { text: 'PR Date', value: 'dt_pr', sortable: false, width: 80 },
                { text: 'Dept Name', value: 'dept_name', sortable: false, width: 80 },
                { text: 'PIC Name', value: 'pic_name', sortable: false, width: 80 },
                { text: 'memo', value: 'memo_txt', sortable: false, width: 500 },
                { text: 'Status', value: 'stat', sortable: false, width: 20 },
                { text: 'Approve',  value: 'approve1', align: 'center', sortable: false, width: 80 },
                // { text: 'Approve 2',  value: 'approve2', align: 'center', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            header_po_items:[
                {
                    text: 'PR ID',
                    align: 'start',
                    sortable: true,
                    value: 'pr_id',
                },
                { text: 'PR Item', value: 'pr_item' },
                { text: 'Article', value: 'description_article' },
                { text: 'Qty', value: 'qty' },
                { text: 'Weight', value: 'wgt' },
                { text: 'Status', value: 'stat' },
                { text: 'Remark', value: 'remark' }
            ],
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'PPSK',
                appl_id : 'WEBPPSK'
            },
            user_approve1: '',
            user_approve2: '',
            dialogAgree: false,
            dialogAgree2: false,
            dialogDisagree2: false,
            dialogDisagree: false,
            pagination: 1,
            pagination_item: 1,
            loading_reject: false,
            sortBy: 'desc',
            sortName: '',
            searchText: false,
            search: ''
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  

        await this.getOffice()
        await this.getEnvConf()

        this.$store.dispatch('setOverlay', false)  

    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRV1') {
                        this.user_approve1 = res.data.data[i]['var_value']

                        if (this.user_approve1 != null) {
                            this.approve = 'approve1'

                            this.getPullData()

                        }
                    }
                }

                if (this.approve === '' && this.user_approve1 === null && this.user_approve2 === null) {
                    this.approve = 'all'
                    this.getPullData()
                }

            })
        },
        search_temp(){
            this.pagination_item = 1
            this.getPullDataDetail(this.po_item)
        },
        close() {
            this.dialog = false
        },
        showItem(item){
            console.log(item);
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(this.po_item)
        },
        async getPullDataDetail(item){
            this.$store.dispatch('setOverlay', true)  

            await axios.get(`${process.env.VUE_APP_URL}/api/ppsk/purchase_request/show?search=${this.search_detail}&pr_id=${item.pr_id}&txtOffice=${item.office_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)  
                this.po_items = res.data
            })
        },
        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.getPullData()
        },
        async getPullData(){
            this.$store.dispatch('setOverlay', true)  

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/ppsk/purchase_request?txtOffice=${this.office ? this.office : ""}&txtDepartment=${this.department ? this.department : ""}&txtApproved=${this.approve ? this.approve : ""}&txtStatus=${this.status ? this.status : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.display = 'block'
                this.$store.dispatch('setOverlay', false)  
                this.purchases = res.data
                console.log(this.purchases)
            })
        },
        getOffice(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/ppsk/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data

                this.office = res.data[0].office_id
                this.updateDepartment(this.office)
            });
        },
        updateDepartment(value){
            this.loading2 = true
            axios.get(`${process.env.VUE_APP_URL}/api/master/ppsk/department?office_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.departments = res.data
                this.loading2 = false
            });
        },
        setApprove1(item){
            this.dialogAgree = true
            this.po_item = item
        },
        setApprove2(item){
            this.dialogAgree2 = true
            this.po_item = item
        },
        setUnApprove(item){
            this.dialogDisagree = true
            this.po_item = item
        },
        setUnApprove2(item){
            this.dialogDisagree2 = true
            this.po_item = item
        },
        async submitReject(){
            this.loading_reject = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ppsk/purchase_request/reject`, {
                'pr_id': this.po_item.pr_id
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading_reject = false
                this.dialogAgree = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Reject",
                    visible: true
                };
            }).catch(err => {
                this.loading_reject = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitApprove1(){
            this.loading4 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ppsk/purchase_request/approve`, {
                'pr_id': this.po_item.pr_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading4 = false
                this.dialogAgree = false
                this.getPullData()
                this.submitApprove2()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };
            }).catch(err => {
                this.loading4 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitApprove2(){
            // this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/sr_mall/purchase_request/approve`, {
                'pr_id': this.po_item.pr_id,
                'approve': 2
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                // this.loading5 = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 2",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitUnApprove(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ppsk/purchase_request/unapprove`, {
                'pr_id': this.po_item.pr_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogDisagree = false
                this.getPullData()
                this.submitUnApprove2()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully UnApprove 1",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitUnApprove2(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/sr_mall/purchase_request/unapprove`, {
                'pr_id': this.po_item.pr_id,
                'approve': 2
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogDisagree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully UnApprove 2",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        
    },
    watch: {
        // options: {
        //     handler () {
        //         const { page, itemsPerPage } = this.options
        //         if (this.approve) {
        //             this.getPullData(page, itemsPerPage, this.searchText)
        //         }
        //     },
        //     deep: true,
        // },
        // option_po_items: {
        //     handler () {
        //         const { page, itemsPerPage } = this.option_po_items
        //         if (this.po_item) {
        //             this.getPullDataDetail(page, itemsPerPage, this.po_item)
        //         }
        //     },
        //     deep: true,
        // },
    }
}
</script>